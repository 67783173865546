class PopOverServiceController {
  private popOver = null;

  async presentPopover(ev, component, componentProps, cssClass,id="popover", showBackdrop = false) {
    this.popOver = Object.assign(document.createElement('ion-popover'), {
      id,
      component,
      componentProps,
      event: ev,
      cssClass,
      showBackdrop
    });

    const child = document.body.appendChild(this.popOver);
    await this.popOver.present();
    await this.popOver.onDidDismiss();
    child.remove();
  }

  async dismiss(ev?) {
    await this.popOver.dismiss(ev);
  }
}

export const PopOverService = new PopOverServiceController();